import PropTypes from "prop-types";
import React from "react";

const hero = ({ background, children, large }) => (
	<div
		className={`hero ${large ? "hero-large" : ""}`}
		style={{
			backgroundImage: `url(${background})`,
		}}
	>
		<div className="container">
			<div className="row">{children}</div>
		</div>
	</div>
);

hero.propTypes = {
	background: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	large: PropTypes.bool,
};

export default hero;
